.Technologies{
    height: 100vh;
    margin: auto;
    width: 70%;
    background-color: cyan;
    display:flex;
    justify-content: center;
    align-items: center;
}
.Technologies__header{
    width: 40%;
    text-align: center;
    border:red 3px solid;
    padding: 1rem;
    border-radius: 10px;
    
}
.Technologies__header img{
    width: 20% !important;
}