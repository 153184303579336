@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --primary-color: #edf2f8;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --circlNavDark: "";

  --backgroundColor: #162d53;
  --btnBackground: #132347;
  --primaryColor: #7865FF;
  --secondary-color: #313bac;
  --gray-color: #d7e6ff;
  --whiteColor: #fefeff;
  --darkNav: transparent;
  --borderDarkNav: rgba(255, 255, 255, 0.18);
  --black: #000;
  --blackTech: #000;
  --secondary-color-footer: #313bac;
  --backgroundTable: #132347;
  --primaryColorTable: #7865FF;
  --navLinksColorDark: #d7e6ff;
  --navLinksColorDarkSocial: #d7e6ff;
  --DesignerTextDark: #7865FF;
  --tableBackgroundDark:#7865FF;
  --aqua:#3AA6EF;
  --white:#fff;
  --itemsFilteredColor:#313bac;
  --itemsFilteredColorHover:#7865FF;

  --lightPrimaryColor: #162032;
  --purpleColor: #9C27B0;
  --primaryHover: #013876;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  transition: .4s;
 
}