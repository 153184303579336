.design_top {
    color: #7865ff;
    margin-bottom: 15px;
}

.design_card {
    position: relative;
    z-index: 90;
    overflow: hidden;

}

.design_card:hover .design_content {
    transform: translateY(0);
    opacity: 1
}


.design_img img {
    border-radius: 5px;
    width: 100%;
}

.hide {
    display: none;
}

.show {
    display: block
}

.btn {
    color: #fff !important;
    font-weight: 500;

}

.showBtn {
    display: block !important;
}

img {
    width: 100%;
}

.img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.allImages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.spaceGirl {
    grid-column: 1/3;

}

.PATRONUS {
    grid-column: 4/6;
}

.crackedGirl {
    grid-column: 2/4
}

.childCave {
    grid-column: 4/6
}

.storm {
    grid-column: 3/5
}

.glass {
    grid-column: 3/6
}

.gamingPoster {
    grid-column: 1/6
}

.crackedSoul {
    grid-column: 2/4
}

.amir {
    grid-column: 3/5
}
.Angel_girl {
    grid-column: 1/3
}
.raven {
    grid-column: 3/5
}

.app__gallery-filter-item {
    border-radius: 0.5rem;
    background-color: rgba(208, 232, 224, 0.562);
    color: #000;
    font-weight: 800;
    transition: all 0.3s ease;
    margin: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &:hover{
        background-color: #3da6ed;
        
    }
    img{
        padding: .3rem;
        border-radius: 0.5rem;
        height: 100%;
    }
}
.app__work-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    // transform: translateY(-50px) !important;
}

.item-active-button {
    color: #000;
    border:2px solid var(--secondary-color)
}
.item-active-button:hover {
    background-color: var(--secondary-color);
    color: #fff;
}
.plusIcon{
    svg{
        font-size: 30px;
        margin-top: 20px;
        cursor: pointer;
        color:var(--secondary-color);

        &:hover{
            color:#3BA5EC;
            transition: .2s !important;
        }
    }
}