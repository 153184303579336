#home {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;

    width: 100% !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--backgroundColor);

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0rem;
    margin-top: 30px;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }

    .app__header-badge {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        .badge-cmp,
        .tag-cmp {
            padding: 1rem 2rem;
            background: var(--secondary-color);
            border-radius: 15px;
            flex-direction: row;
            width: auto;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        }

        .tag-cmp {
            flex-direction: column;
            margin-top: 3rem;

            p {
                width: 100%;
                text-transform: uppercase;
                text-align: right;
                font-size: .8rem;
                color: var(--navLinksColorDarkSocial) !important;
            }
        }

        span {
            font-size: 2.5rem;

            @media screen and (min-width: 2000px) {
                font-size: 5rem;
            }
        }

        @media screen and (max-width: 1200px) {
            justify-content: flex-start;
            align-items: flex-start;
        }


    }

    .app__header-circles-level2 {
        flex: 0.75;
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        div {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: var(--secondary-color);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

            img {
                width: 60%;
                height: 60%;
            }
        }

        div:nth-child(1) {
            width: 100px;
            height: 100px;
            margin-top: -90px;

        }

        div:nth-child(2) {
            margin: 50px 0 0 80px;
            width: 150px;
            height: 150px;
        }

        div:nth-child(3) {
            width: 85px;
            height: 85px;
        }

        div:nth-child(4) {
            margin: 0 4.75rem;
            width: 100px;
            height: 100px;
        }

        @media screen and (min-width: 2000px) {
            div:nth-child(1) {
                width: 200px;
                height: 200px;
            }

            div:nth-child(2) {
                width: 400px;
                height: 400px;
            }

            div:nth-child(3) {
                width: 170px;
                height: 170px;
            }

        }

        @media screen and (max-width: 1200px) {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 0;

            div {
                margin: 1rem;
            }
        }
    }
}

.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    margin-top: -60px;

    .app__header-img-class {
        width: 85%;
        object-fit: contain;
        z-index: 1;
        margin-top: 65px;
        text-align: center;
        border-radius: 50%;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
        background: url('../../assets/circle2.png');
        background-size: cover;
        background-position: center;
        background-repeat: repeat;
        border-radius: 50000px;

    }


}


.app__header-circles {
    flex: 0.60;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
    width: 100%;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--secondary-color);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
        margin-right: 80px;

    }

    div:nth-child(2) {
        margin: 0 0 0 5.75rem;
        width: 150px;
        height: 150px;
    }


    div:nth-child(3) {
        margin: 0 0 0 0.75rem;
        width: 120px;
        height: 120px;
    }


    div:nth-child(4) {
        width: 100px;
        height: 100px;
        margin: 20px 0 0 -5rem;
    }

}


@media (max-width: 1024px) {

    .app__header-circles {

        div {

            img {
                width: 85%;
                height: 85%;
            }
        }

        div:nth-child(1) {
            width: 100px;
            height: 100px;
            margin-right: 0px;
            margin-left: 0rem;
            margin: 0 0 0 24.75rem;
        }

        div:nth-child(2) {
            margin: 0 0 0 29.75rem;
            width: 110px;
            height: 110px;
        }


        div:nth-child(3) {
            margin: 0 0 0 22.75rem;
            width: 90px;
            height: 90px;
        }


        div:nth-child(4) {
            width: 70px;
            height: 70px;
            margin: 20px 0 0 15.75rem;
        }

    }


    .app__header-img {
        height: 80%;
        margin-top: -60px;
        margin-bottom: 50px;

        .app__header-img-class {
            width: 50%;
            margin-top: 95px;
            
        }

        .overlay_circle {
            width: 65%;
            height: 85%;
            border-radius: 50%;
            margin:auto
        }

    }

}

@media (max-width: 770px) {

    .app__header-circles {

        div {

            img {
                width: 75%;
                height: 75%;
            }
        }

        div:nth-child(1) {
            width: 100px;
            height: 100px;
            margin-right: 0px;
            margin-left: 0rem;
            margin: 0 0 0 11.75rem;
        }

        div:nth-child(2) {
            margin: 0 0 0 17.75rem;
            width: 110px;
            height: 110px;
        }


        div:nth-child(3) {
            margin: 0 0 0 10.75rem;
            width: 90px;
            height: 90px;
        }


        div:nth-child(4) {
            width: 70px;
            height: 70px;
            margin: 20px 0 0 6.75rem;
        }

    }

}

@media (max-width: 550px) {

    .app__header-circles {

        div {

            img {
                width: 65%;
                height: 65%;
            }
        }

        div:nth-child(1) {
            width: 80px;
            height: 80px;
            margin-right: 0px;
            margin-left: 0rem;
            margin: 0 0 0 4.75rem;
        }

        div:nth-child(2) {
            margin: 0 0 0 9.75rem;
            width: 110px;
            height: 110px;
        }


        div:nth-child(3) {
            margin: 0 0 0 5.75rem;
            width: 90px;
            height: 90px;
        }


        div:nth-child(4) {
            width: 70px;
            height: 70px;
            margin: 20px 0 0 2.75rem;
        }

    }


    .app__header-img {
        height: 80%;
        margin-top: -60px;
        margin-bottom: 50px;

        .app__header-img-class {
            width: 70%;
            margin-top: 355px;
            
        }

        .overlay_circle {
            width: 100%;
            height: 50%;
            border-radius: 50%;

        }

    }
}